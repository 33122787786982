// 组件
import importImg from '@/components/importImg.vue'
import alterForm from '@/components/alterForm'
// 接口
import { getOnlineCouponsEditDetails, getOnlineCouponsConfigurations, getOnlineCouponsShopList, getOnlineCouponsShopProduct, onlineCouponAddEdit, getSiteSimpList } from '@/services/coupon.js'
// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'

export default {
  name: 'onlineCouponEdit',
  components: {
    importImg,
    alterForm
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData,
      btnTxt: this.$t('button.confirm'),
      applyToOptions: [
        {
          label: this.$t('form.coupon.entireShop'),
          value: 'entire_shop'
        },
        {
          label: this.$t('form.coupon.selectedProducts'),
          value: 'selected_products'
        }
      ]
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    init () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      getOnlineCouponsConfigurations().then(res => {
        if (res.status === 200) {
          this.formData[0].createDateItem[21].options = res.data.couponable_types
          this.formData[0].createDateItem[6].options = res.data.coupon_types
          this.formData[0].createDateItem[5].options = res.data.shipping_methods
          this.formData[0].createDateItem[13].options = res.data.valid_types
          this.formData[0].createDateItem[3].options = res.data.collection_methods
          this.formData[0].createDateItem[24].options = this.applyToOptions
        }
      })
      getOnlineCouponsEditDetails(this.$route.params.id).then(res => {
        if (res.status === 200) {
          this.modifyData = res.data.coupon
          this.modifyData.images_attributes = this.modifyData.images
          this.getShops(this.modifyData.couponable_type)
          if (this.modifyData.couponable_type === 'ShopItem') {
            if (this.modifyData.poi_ids.length === 1 && this.modifyData.poi_ids[0] === 0) {
              this.modifyData.apply_to = this.applyToOptions[0].value
            } else {
              this.modifyData.apply_to = this.applyToOptions[1].value
            }
          }
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
      // this.getProduct()
    },
    handleChange (data, prop) {
      console.log('-------------->', data, prop)
      if (prop === 'couponable_type') {
        if (data === 'Shop' || data === 'ShopItem') {
          this.getShops(data)
        }
        if (data === 'Site') {
          this.getSites()
        }
      }
      if (prop === 'apply_to') {
        if (data === 'selected_products') {
          this.btnTxt = this.$t('button.next')
        } else {
          this.btnTxt = this.$t('button.confirm')
        }
      }
    },
    getSites () {
      getSiteSimpList().then(res => {
        if (res.status === 200) {
          res.data.sites.unshift({ id: 0, name: this.$t('form.coupon.all') })
          this.formData[0].createDateItem[22].options = res.data.sites
        }
      })
    },
    getShops (type) {
      getOnlineCouponsShopList().then(res => {
        if (res.status === 200) {
          if (type === 'Shop') {
            res.data.shops.unshift({ id: 0, name: this.$t('form.coupon.all') })
          }
          this.formData[0].createDateItem[22].options = res.data.shops
          this.formData[0].createDateItem[23].options = res.data.shops
        }
      })
    },
    getProduct (shop_id) {
      const params = {
        shop_id,
        all: true,
        only_name: true
      }
      getOnlineCouponsShopProduct(params).then(res => {
        res.data.shop_items.unshift({ id: 0, name: this.$t('form.coupon.all') })
        this.formData[0].createDateItem[16].options = res.data.shop_items
      })
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data))
      const params = {
        online_coupon: obj
      }
      onlineCouponAddEdit(params, this.$route.params.id).then(res => {
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            this.$router.push({
              name: 'couponManagerOnlineCouponList'
            })
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    }
  }
}
